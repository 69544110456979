import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ThankYouPage = ({ data, location }) => {
  const siteTitle = data?.site?.siteMetadata?.title || `Thank You!`
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Thank you" />
      <div className="p-1 h-auto flex flex-col items-center pt-3 md:justify-evenly">
        <div className="md:self-center lg:w-1/2 font-nunito p-3">
          <h2 className="text-3xl text-center md:text-6xl dark:text-slate-800">
            Your message was successfully submitted.
          </h2>
          <h3 className="pt-3 text-lg md:text-xl text-center text-stone-700 dark:text-slate-900">
            Thanks for reaching out! I look forward to reading your message.
          </h3>
        </div>
      </div>
    </Layout>
  )
}

export default ThankYouPage
